import React, { useEffect, useState } from 'react'
import './style.scss'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SinglePage from '../../../components/common/page/SinglePage'
import NormalInput from '../../../components/common/inputs/NormalInput'
import SelectInput from '../../../components/common/inputs/SelectInput'
import SignCanvas from '../../../components/common/signatureCanvas/SignCanvas';
import { FaDotCircle } from 'react-icons/fa'
import { BiLoaderAlt } from 'react-icons/bi'
import { userAxios } from '../../../config/axios'
import toast from 'react-hot-toast'

const ServiceForm = () => {
    const { user } = useSelector((state) => state.userAuth)
    const [searchParams, setSearchParams] = useSearchParams();
    const [find, setFind] = useState('')
    const [customer, setCustomer] = useState({})
    const [loading, setLoading] = useState('')
    const [form, setForm] = useState({})
    const navigate = useNavigate()

    const workTypes = [
        { option: 'Complaint', value: 'Complaint' },
        { option: 'Quality checking', value: 'Quality checking' }
    ]

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleFindSubmit = (e) => {
        e.preventDefault();
        setLoading('find')
        userAxios.get(`/customer-list?cid=${find}`).then((response) => {
            setCustomer(response.data)
            setForm({
                ...form,
                cid: response.data.cid,
                name_of_customer: response.data.full_name,
                zone_id: response.data.zone_id
            })
            setLoading('')
        }).catch((error) => {
            toast.error(error.message)
            setLoading('')
        })
    }

    const saveSignature = (url) => {
        setForm({
            ...form,
            signature: {
                ...(form.signature || {}),
                url: url
            }
        })
    }

    const resetSignature = () => {
        setForm({
            ...form,
            signature: {
                ...(form.signature || {}),
                url: null
            }
        })
    }

    const resetData = () => {
        setForm({})
        setFind('')
        setCustomer({})
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoading('submit')
        userAxios.post('/service/service-form-submit', form).then((response) => {
            setForm({})
            setFind('')
            setCustomer({})
            setLoading('')
            toast.success('Form successfully submitted !')
        }).catch((error) => {
            setLoading('')
            toast.error(error.message)
        })

    }

    useEffect(() => {
        const id = searchParams.get('id');
        if (!id && user._id) {
            setSearchParams(`id=${user._id}`)
        }
        // eslint-disable-next-line
    }, [])


    return (
        <div className="service-form-div">
            <SinglePage titleArray={['Service form']}>
                <div className="border-div">
                    <div className="form-border-div">
                        <div className="section-one-div">
                            <form action="" onSubmit={handleFindSubmit}>
                                <NormalInput label='Customer Id' name='cid' value={find} onChangeFun={(e) => setFind(e.target.value)} type={'number'} />
                                <div className="button-div">
                                    <button type='button' onClick={resetData}>Reset</button>
                                    <button>{loading === 'find' ? <span className='loading-icon'><BiLoaderAlt /></span> : 'Find'}</button>
                                </div>
                            </form>
                        </div>
                        {customer?.cid && <div className="section-two-div">
                            {/* Personal Details */}
                            <div className="list-border-div">
                                <div className="list-title">
                                    <FaDotCircle />
                                    <h4>Personal details</h4>
                                </div>
                                <div className="title-content">
                                    <div className="list-div">
                                        <div className="one-div">
                                            <p>Customer ID</p>
                                        </div>
                                        <div className="two-div">
                                            <p>: {customer?.cid}</p>
                                        </div>
                                    </div>
                                    <div className="list-div">
                                        <div className="one-div">
                                            <p>Customer name</p>
                                        </div>
                                        <div className="two-div">
                                            <p>: {customer?.full_name} <span className={`text-badge ${customer?.wh_customer_status}-text`}>
                                                {customer?.wh_customer_status}</span></p>
                                        </div>
                                    </div>
                                    <div className="list-div">
                                        <div className="one-div">
                                            <p>Address, Place</p>
                                        </div>
                                        <div className="two-div">
                                            <p>: {customer?.address?.address}, {customer?.address?.place}</p>
                                        </div>
                                    </div>
                                    <div className="list-div">
                                        <div className="one-div">
                                            <p>Post, Land mark</p>
                                        </div>
                                        <div className="two-div">
                                            <p>: {customer?.address?.post} (P.O), {customer?.address?.land_mark}</p>
                                        </div>
                                    </div>
                                    <div className="list-div">
                                        <div className="one-div">
                                            <p>District, Pin</p>
                                        </div>
                                        <div className="two-div">
                                            <p>: {customer?.address?.district}, {customer?.address?.pin_code}</p>
                                        </div>
                                    </div>
                                    <div className="list-div">
                                        <div className="one-div">
                                            <p>State</p>
                                        </div>
                                        <div className="two-div">
                                            <p>: {customer?.address?.state}</p>
                                        </div>
                                    </div>
                                    <div className="list-div">
                                        <div className="one-div">
                                            <p>Contact 1</p>
                                        </div>
                                        <div className="two-div">
                                            <p>: {customer?.contact1}</p>
                                        </div>
                                    </div>
                                    <div className="list-div">
                                        <div className="one-div">
                                            <p>Contact 2</p>
                                        </div>
                                        <div className="two-div">
                                            <p>: {customer?.contact2}</p>
                                        </div>
                                    </div>
                                    <div className="list-div">
                                        <div className="one-div">
                                            <p>Whatsapp</p>
                                        </div>
                                        <div className="two-div">
                                            <p>: {customer?.whatsapp1}</p>
                                        </div>
                                    </div>
                                    <div className="list-div">
                                        <div className="one-div">
                                            <p>Zone</p>
                                        </div>
                                        <div className="two-div">
                                            <p>: {customer?.zone}</p>
                                        </div>
                                    </div>
                                    <div className="list-div">
                                        <div className="one-div">
                                            <p>Product name</p>
                                        </div>
                                        <div className="two-div">
                                            <p>: {customer?.product_name}</p>
                                        </div>
                                    </div>
                                    <div className="button-div">
                                        <button onClick={() => navigate(`/history/${customer?.cid}/cards`)}>Service history</button>
                                    </div>

                                </div>
                            </div>
                        </div>}
                        {customer?.cid && <div className="section-three-div">
                            <h3>Service form</h3>
                            <form action="" onSubmit={handleFormSubmit}>
                                <SelectInput label='Work type' name='work_type' values={workTypes} firstOption={{ option: 'Choose', value: '' }}
                                    onChangeFun={handleChange} />
                                <NormalInput label='Work description' name='work_description' value={form.work_description} onChangeFun={handleChange} />
                                <NormalInput label='Condition signed customer name' name='customer_name' value={form.customer_name} onChangeFun={handleChange} />
                                <SignCanvas signImg={form?.signature?.url} saveSign={saveSignature} resetSign={resetSignature} />
                                <div className="button-div">
                                    <button type={loading === 'submit' ? 'button' : 'submit'}>{loading === 'submit' ? <span className='loading-icon'><BiLoaderAlt /></span> : 'Submit'}</button>
                                </div>
                            </form>
                        </div>}
                    </div>
                </div>
            </SinglePage>
        </div>
    )
}

export default ServiceForm