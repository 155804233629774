import React from 'react'
import './not-found.scss'
import AdminPage from '../../../components/common/page/AdminPage'
import Gif404 from '../../../assets/images/404.gif'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function NotFound() {
  const { admin } = useSelector((state) => state.adminAuth)
  const navigate = useNavigate()

  return (
    <div className='not-found-div'>
      <AdminPage>
        <div className="content-div">
          <div className="content">
            <div className="image">
              <img src={Gif404} alt="" />
              <h4>Page Not Found  : )</h4>
            </div>
            <div className="text">
              {admin ?
                <button onClick={() => navigate(`/admin?id=${admin?._id}`)}>Home Page</button>
                :
                <button onClick={() => navigate('/login')}>Login</button>
              }
            </div>
          </div>
        </div>
      </AdminPage>
    </div>
  )
}

export default NotFound