import React, { useEffect, useState } from 'react'
import './view-edit.scss';
import AdminPage from '../../../components/common/page/AdminPage'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast';
import { customerAxios, staffAxios, userAxios } from '../../../config/axios';
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import NormalInput from '../../../components/common/inputs/NormalInput';
import SelectInput from '../../../components/common/inputs/SelectInput';
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions'
import { BiLoaderAlt } from "react-icons/bi";
import InfoBox from '../../../components/common/info-box/InfoBox'
import Modal from '../../../components/common/modal/Modal'

const ViewEditCustomer = () => {
    const { cid } = useParams();
    const [customer, setCustomer] = useState({})
    const [temp, setTemp] = useState({})
    const [wholeHouseList, setWholeHouseList] = useState([])
    const [productUsageList, setProductUsageList] = useState([])
    const [creationType, setCreationType] = useState([])
    const [enquiryType, setEnquiryType] = useState([])
    const [staffs, setStaffs] = useState([])
    const [enquiryStaff, setEnquiryStaff] = useState([])
    const [refillStaff, setRefillStaff] = useState([])
    const [whStatus, setWhStatus] = useState([])
    const [adzType, setAdzType] = useState([])
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const [loading, setLoading] = useState('fetch')
    const navigate = useNavigate()
    const [save, setSave] = useState(false)

    const openModal = (elem, head, data) => {
        setModal({
            content: elem,
            title: head,
            status: true
        })
    }

    const handleChange = (e) => {
        setSave(true)

        if (e.target.name === 'enquiry_type') {
            setCustomer({
                ...customer,
                [e.target.name]: e.target.value,
                enquiry_collected_staff: undefined,
                enquiry_collected_cid: undefined,
                care_of_srl: undefined,
                adz_type: undefined
            })
        } else {
            setCustomer({
                ...customer,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleChangeAuto = (name, value) => {
        setSave(true)
        setCustomer({
            ...customer,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        userAxios.put('/customer/service-data', customer).then(() => {
            toast.success('Updated')
            navigate('/admin/customer-list')
        }).catch((error) => {
            toast.error(error.message)
        })
    }

    const fetchData = () => {
        setLoading('fetch')
        customerAxios.get(`/service/whole-house?cid=${cid}&spare=YES`).then((response) => {
            setCustomer(response.data)
            setTemp(response.data)

            setCreationType([
                { option: 'NEW MACHINE', value: 'NEW MACHINE', selected: response.data.creation_type === 'NEW MACHINE' },
                { option: 'O/C', value: 'O/C', selected: response.data.creation_type === 'O/C' }
            ])

            setEnquiryType([
                { option: 'Office enquiry', value: 'Office', selected: response.data.enquiry_type === 'Office' },
                { option: 'C/O enquiry', value: 'C/O', selected: response.data.enquiry_type === 'C/O' },
                { option: 'Enquiry from Adz', value: 'Adz', selected: response.data.enquiry_type === 'Adz' },
            ])

            setAdzType([
                { option: 'TV', value: 'TV', selected: response.data.adz_type === 'TV' },
                { option: 'Social media', value: 'Social media', selected: response.data.adz_type === 'Social media' },
                { option: 'Flex', value: 'Flex', selected: response.data.adz_type === 'Flex' },
                { option: 'Other', value: 'Other', selected: response.data.adz_type === 'Other' }
            ])

            setWhStatus([
                { option: 'SSP', value: 'SSP', selected: response.data.wh_customer_status === 'SSP' },
                { option: 'I/W', value: 'I/W', selected: response.data.wh_customer_status === 'I/W' },
                { option: 'O/W', value: 'O/W', selected: response.data.wh_customer_status === 'O/W' },
                { option: 'O/C', value: 'O/C', selected: response.data.wh_customer_status === 'O/C' },
            ])

            setLoading('')
        }).catch((error) => {
            toast.error(error.message)
            setLoading('')
            navigate('/admin/customer-list')
        })
    }

    useEffect(() => {
        if (cid) {
            fetchData()
        }
    }, [cid])

    useEffect(() => {
        if (customer?.cid && !staffs?.[0]) {
            userAxios.get('/products?all=YES&spare_list=NO').then((response) => {
                const options = response.data?.map((product) => {
                    return {
                        option: `${product.product_name}`,
                        value: product._id,
                        selected: customer?.product_id === product._id
                    }
                })
                setWholeHouseList(options)
            })
            staffAxios.get('/admin/staff/all-list?all=yes&nameOnly=yes').then((response) => {
                const options = response.data?.map((staff) => {
                    return {
                        option: `${staff.first_name} ${staff.last_name} ${staff.delete ? '(Removed)' : ""}`,
                        value: staff._id,
                        selected: customer?.installed_by === staff._id
                    }
                })
                setStaffs(options)

                const enquiryOptions = response.data?.map((staff) => {
                    return {
                        option: `${staff.first_name} ${staff.last_name} ${staff.delete ? '(Removed)' : ""}`,
                        value: staff._id,
                        selected: customer?.enquiry_collected_staff === staff._id
                    }
                })
                setEnquiryStaff(enquiryOptions)

                const refillOptions = response.data?.map((staff) => {
                    return {
                        option: `${staff.first_name} ${staff.last_name} ${staff.delete ? '(Removed)' : ""}`,
                        value: staff._id,
                        selected: customer?.last_refilled_by === staff._id
                    }
                })
                setRefillStaff(refillOptions)
            })
        }
    }, [customer])


    return (
        <div className="view-edit-customer-div">
            <Modal modal={modal} setModal={setModal} />
            <AdminPage titleArray={[`Customer list - ${cid} `]}>
                <div className="top-div">
                    <InfoBox type='warning' description={'Your little mistake can lead to a big bug. Edit with careful.'} />
                </div>
                {customer?.cid ? <>
                    <form action="" onSubmit={handleSubmit} style={{ marginBottom: '100px' }}>
                        <div className="section-div">
                            <div className="sub-title-div">
                                <h5>Installation</h5>
                            </div>
                            <div className="input-section-div">
                                <SelectInput label='Whole house Model' name='product_id' values={wholeHouseList} firstOption={{ option: 'Choose', value: '' }}
                                    onChangeFun={handleChange} isRequired={false}/>
                                <NormalInput label='Installed Date' name='installed_at' isRequired={false} value={customer?.installed_at}
                                    onChangeFun={handleChange} type={'date'} max={YYYYMMDDFormat(new Date())} />
                                <SelectInput label='Installed By' name='installed_by' isRequired={false} values={staffs} firstOption={{ option: 'Choose', value: '' }}
                                    onChangeFun={handleChange} />
                                <NormalInput label='Installed Product Price' name='product_price_at_install' isRequired={false} value={customer?.product_price_at_install}
                                    onChangeFun={handleChange} type={'number'} min={0} />
                                <NormalInput label='Installation SRL No' name='installation_srl_no' isRequired={false} value={customer?.installation_srl_no}
                                    onChangeFun={handleChange} />
                                <NormalInput label='Bill Received Date' name='bill_received_date' isRequired={false} value={customer?.bill_received_date}
                                    onChangeFun={handleChange} type={'date'} />
                                <NormalInput label='Last Reinstallation Date' name='last_reinstallation_date' isRequired={false} value={customer?.last_reinstallation_date}
                                    onChangeFun={handleChange} type={'date'} max={YYYYMMDDFormat(new Date())} />
                                <NormalInput label='Last Reinstallation SRL No' name='last_reinstallation_srl_no' isRequired={false} value={customer?.last_reinstallation_srl_no}
                                    onChangeFun={handleChange} />
                                <NormalInput label='Last refilling Date' name='last_refilling_date' isRequired={false} value={customer?.last_refilling_date}
                                    onChangeFun={handleChange} type={'date'} max={YYYYMMDDFormat(new Date())} />
                                <SelectInput label='Last refilled By' name='last_refilled_by' isRequired={false} values={refillStaff} firstOption={{ option: 'Choose', value: '' }}
                                    onChangeFun={handleChange} />
                            </div>
                        </div>

                        <div className="section-div">
                            <div className="sub-title-div">
                                <h5>Enquiry</h5>
                            </div>
                            <div className="input-section-div">
                                <SelectInput label='Creation type' name='creation_type' values={creationType} firstOption={{ option: 'Choose', value: '' }}
                                    onChangeFun={handleChange} isRequired={false} />
                                <NormalInput label='Enquiry srl no' name='enquiry_srl_no' isRequired={false} value={customer?.enquiry_srl_no}
                                    onChangeFun={handleChange} type={'text'} />
                                <SelectInput label='Enquiry type' name='enquiry_type' values={enquiryType} firstOption={{ option: 'Choose', value: '' }}
                                    onChangeFun={handleChange} isRequired={false} />
                                {customer?.enquiry_type === 'Office' && <SelectInput label='Collected by' name='enquiry_collected_staff' values={enquiryStaff} firstOption={{ option: 'Choose', value: '' }}
                                    onChangeFun={handleChange} isRequired={false} />}
                                {customer?.enquiry_type === 'C/O' && <>
                                    <NormalInput label='Collected by (Customer ID)' name='enquiry_collected_cid' isRequired={false} value={customer?.enquiry_collected_cid}
                                        onChangeFun={handleChange} type={'number'} />
                                    <NormalInput label='Care of SRL number' name='care_of_srl' isRequired={false} value={customer?.care_of_srl}
                                        onChangeFun={handleChange} type={'text'} />
                                </>}
                                {customer?.enquiry_type === 'Adz' && <SelectInput label='Adz type' name='adz_type' values={adzType} firstOption={{ option: 'Choose', value: '' }}
                                    onChangeFun={handleChange} isRequired={false} />}

                            </div>
                        </div>

                        <div className="section-div package-service-div">
                            <div className="sub-title-div">
                                <h5>Service package</h5>
                            </div>
                            <div className="input-section-div">
                                <SelectInput label='Package Name' name='wh_customer_status' isRequired={false} values={whStatus} firstOption={{ option: 'Choose', value: '' }}
                                    onChangeFun={handleChange} />

                                <div className="input-sub-div">
                                    <NormalInput label='Package Started Date' name='package_started_date' value={customer?.package_started_date}
                                        onChangeFun={handleChange} type={'date'} min={customer?.installed_at} />
                                    <div className="buttons-div">
                                        <button type='button' className='add' onClick={() => handleChangeAuto('package_started_date', YYYYMMDDFormat(new Date()))}>Set as today</button>
                                        <button type='button' className='delete' onClick={() => handleChangeAuto('package_started_date', temp?.package_started_date || undefined)}>Reset</button>
                                    </div>
                                </div>
                              
                                <div className="input-sub-div">
                                    <NormalInput label='Package Expiry Date' name='package_expiry_date' value={customer?.package_expiry_date}
                                        onChangeFun={handleChange} type={'date'} isRequired={false} min={customer?.package_started_date} />
                                    <div className="buttons-div">
                                        <button type='button' className='add' onClick={() => handleChangeAuto('package_expiry_date',
                                            YYYYMMDDFormat(new Date(new Date(customer?.package_started_date).setFullYear(new Date().getFullYear() + 1))))}>After 1 Year</button>
                                        <button type='button' className='delete' onClick={() => handleChangeAuto('package_expiry_date', temp?.package_expiry_date || undefined)}>Reset</button>
                                    </div>
                                </div>
                                <div className="input-sub-div">
                                    <NormalInput label='Next Periodical Service' name='next_periodical_service_date' value={customer?.next_periodical_service_date}
                                        onChangeFun={handleChange} type={'date'} isRequired={false} min={customer?.package_started_date} max={customer?.package_expiry_date} />
                                    <div className="buttons-div">
                                        <button type='button' className='add' onClick={() => handleChangeAuto('next_periodical_service_date',
                                            YYYYMMDDFormat(new Date(new Date().setMonth(new Date().getMonth() + 3))))}>After 3 months (Today)</button>
                                        <button type='button' className='delete' onClick={() => handleChangeAuto('next_periodical_service_date', temp?.next_periodical_service_date || undefined)}>Reset</button>
                                    </div>
                                </div>
                                <div className="input-sub-div">
                                    <NormalInput label='Technician Last Visited' name='technician_last_visited_date' value={customer?.technician_last_visited_date}
                                        onChangeFun={handleChange} type={'date'} isRequired={false} />
                                    <div className="buttons-div">
                                        <button type='button' className='add' onClick={() => handleChangeAuto('technician_last_visited_date',
                                            YYYYMMDDFormat(new Date()))}>Set as today</button>
                                        <button type='button' className='delete' onClick={() => handleChangeAuto('technician_last_visited_date', temp?.technician_last_visited_date || undefined)}>Reset</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {save && <div className="button-div">
                            <button type={loading ? 'button' : 'submit'}>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : 'Save Changes'}</button>
                        </div>}
                    </form>
                </>
                    : <Spinner message={'fetch data...'} />
                }
            </AdminPage>
        </div>
    )
}

export default ViewEditCustomer