import React from 'react'
import SinglePage from '../../../components/common/page/SinglePage';
import CustomerCardDetails from '../../../components/user/customer-details/CustomerCardDetails';
import { useParams } from 'react-router-dom';

const CustomerDetails = () => {
  const { cid } = useParams();


  return (
    <div className="customer-details-div">
      <SinglePage titleArray={['Customer details']}>
        <CustomerCardDetails cid={cid} />
      </SinglePage>
    </div>
  )
}

export default CustomerDetails