const YYYYMMDDFormat = (ISOdate, symbol = '-') => {
    if (!ISOdate) {
        return '-';
    }

    symbol = symbol ? symbol : ''
    ISOdate = new Date(ISOdate)
    const year = ISOdate.getFullYear();
    const month = String(ISOdate.getMonth() + 1).padStart(2, '0');
    const day = String(ISOdate.getDate()).padStart(2, '0');

    return `${year}${symbol}${month}${symbol}${day}`;
}

const ISOtoDateTimeInput = (ISOdate) => {
    let formatting = ISOdate.setHours(new Date().getHours() + 5)
    formatting = new Date(formatting).setMinutes(new Date(formatting).getMinutes() + 30)
    formatting = new Date(formatting).toISOString().slice(0, 16)

    return formatting
}

const formatTimeSeconds = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    const pad = (value) => (value < 10 ? `0${value}` : value);

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

const customerStatusInSmall = (status) => {
    switch (status) {
        case 'I/W':
            return 'iw'
        case 'O/W':
            return 'ow'
        case 'O/C':
            return 'oc'
        case 'AMC':
            return 'amc'
        case 'SSP':
            return 'ssp'
        default:
            break;
    }
}

const formatStringDate = (inputDate) => {
    if (!inputDate) {
        return '-';
    }

    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = new Date(inputDate).toLocaleDateString(undefined, options);
    return formattedDate;
}


const getTimeFromSecond = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    let time = ''
    if (hours) {
        time = `${hours}h `
    }
    if (minutes) {
        time = time + `${minutes}m`
    }
    return time;
}

function convertBytesToReadable(bytes) {
    if (bytes < 1024) {
        return bytes.toFixed(2) + " B";
    } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes < 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    } else {
        return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    }
}


export { YYYYMMDDFormat, ISOtoDateTimeInput, formatTimeSeconds, customerStatusInSmall, formatStringDate, getTimeFromSecond, convertBytesToReadable }