import React, { useEffect, useState } from 'react'
import './add-edit-zone.scss'
import MultiSelect from '../../common/inputs/MultiSelect';
import NormalInput from '../../common/inputs/NormalInput';
import { BiLoaderAlt } from 'react-icons/bi'
import { staffAxios, userAxios } from '../../../config/axios';
import toast from 'react-hot-toast';


const AddEditZone = ({ data, setModal, setZone }) => {
    const [loading, setLoading] = useState('')
    const [form, setForm] = useState({
        staff_ids: data?.technicians?.map((tech) => tech.staff_id) || [],
        zone: data?.zone || '',
        wh_get_data_date: data?.wh_get_data_date || ''
    })
    const [staff, setStaff] = useState([])

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleMultiSelect = (selected) => {
        setForm({
            ...form,
            staff_ids: selected.map((tech) => tech.value)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading('submit')
        if (!form?.staff_ids?.[0]) {
            toast.error('Select technician')
            return;
        }
        if (data) {
            userAxios.put('/zone', {
                _id: data.zone_id,
                staff_ids: form.staff_ids,
                zone: form?.zone,
                wh_get_data_date: form?.wh_get_data_date
            }).then(() => {
                setModal({ status: false })
                setZone((state) => state.map((zone) => {
                    if (zone.zone_id === data.zone_id) {
                        let technicians = staff.filter(objB => form.staff_ids.includes(objB.value));
                        technicians = technicians.map((value) => ({ full_name: value.label, staff_id: value.value }))
                        return {
                            ...zone,
                            wh_get_data_date: form?.wh_get_data_date,
                            technicians,
                            zone: form?.zone,
                        }
                    }
                    return zone
                }))
                setLoading('')
            }).catch((error) => {
                toast.error(error.message || 'Try now')
                setLoading('')
            })
        } else {
            userAxios.post('/zone', {
                staff_ids: form.staff_ids,
                zone: form?.zone
            }).then((response) => {
                setModal({ status: false })
                let technicians = staff.filter(objB => response?.data?.wh_technicians.includes(objB.value));
                technicians = technicians.map((value) => ({ full_name: value.label, staff_id: value.value }))
                setZone((state) => ([
                    {
                        wh_get_data_date: response?.data?.wh_get_data_date,
                        zone: response?.data?.zone,
                        zone_id: response?.data?._id,
                        technicians
                    },
                    ...state
                ]))
                setLoading('')
            }).catch((error) => {
                toast.error(error.message || 'Try now')
                setLoading('')
            })
        }
    }

    useEffect(() => {
        setLoading('fetch')
        staffAxios.get('/admin/staff/all-list?nameOnly=yes').then((response) => {
            if (response.data?.[0]) {
                setStaff(response.data.map((tech) => ({ label: `${tech.first_name} ${tech.last_name}`, value: tech._id })))
            }
            setLoading('')
        })
    }, [])

    return (
        <div className="add-edit-zone-div">
            <form onSubmit={handleSubmit}>
                <NormalInput type={'text'} label='Zone' name='zone' value={form?.zone} onChangeFun={handleChange} />
                {data && <NormalInput type={'date'} label='Assign date' name='wh_get_data_date' value={form?.wh_get_data_date} onChangeFun={handleChange} />}
                <MultiSelect label='Technicians' name='technicians' selectedValue={data?.technicians?.map((tech) => ({ label: tech.full_name, value: tech.staff_id }))}
                    values={staff} onChangeFun={handleMultiSelect} />

                <div className="button-div">
                    <button type={loading === 'submit' ? 'button' : 'submit'}>{loading === 'submit' ? <span className='loading-icon'><BiLoaderAlt /></span> : 'Submit'}</button>
                </div>
            </form>
            {loading === 'fetch' && <div className="loading-pop">
                <p>Loading...</p>
            </div>}
        </div>
    )
}

export default AddEditZone