import React, { useEffect } from 'react'
import { Routes, Route, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../redux/features/user/userAuthSlice'
import { baseIP } from '../config/axios'
import ServiceForm from '../pages/user/service-form/ServiceForm'
import CustomerDetails from '../pages/user/customer-details/CustomerDetails'



function User() {
  const { user } = useSelector((state) => state.userAuth)
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch()
  let isAuthenticated = false

  if (user?.token) {
    isAuthenticated = true
  }

  useEffect(() => {
    const id = searchParams.get('id');

    if (!id && !user?._id) {
      window.location.href = `http://staff.alliancewatersolutions.com`
    } else if (id || user?._id) {
      dispatch(loginUser(id || user?._id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Routes>
      {/* Home */}
      <Route path='/' element={<PrivateRoute element={<ServiceForm />} isAuthenticated={isAuthenticated} />} />
      <Route path='/history/:cid/cards' element={<PrivateRoute element={<CustomerDetails />} isAuthenticated={isAuthenticated} />} />
    </Routes>
  )
}

function PrivateRoute({ element, isAuthenticated }) {
  return isAuthenticated ? (
    <Routes>
      <Route path='/' element={element} />
    </Routes>
  ) : ""

}

export default User