import React, { useEffect, useState } from 'react'
import './home.scss'
import AdminPage from '../../../components/common/page/AdminPage'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Modal from '../../../components/common/modal/Modal'


function Home() {
    const { admin } = useSelector((state) => state.adminAuth)
    const [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const navigate = useNavigate()

    useEffect(() => {
        const id = searchParams.get('id');
        if (!id && admin._id) {
            setSearchParams(`id=${admin._id}`)
        }
        // eslint-disable-next-line
    }, [])


    return (
        <div className="home-page-div">
            <Modal modal={modal} setModal={setModal} />
            <AdminPage titleArray={[`Name : ${admin.first_name} ${admin.last_name}`, `Designation : ${admin?.designation?.designation}`]}>
                <div className="home-border">
                    {/* <div className="button-div" >
                        <button onClick={() => navigate('/admin/zone-list')} >ZONE LIST</button>
                    </div> */}
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/customer-list')} >CUSTOMER LIST</button>
                    </div>
                </div>
            </AdminPage >
        </div >
    )
}

export default Home