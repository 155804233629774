import React, { useEffect, useState } from 'react'
import './style.scss'
import { FaDotCircle } from "react-icons/fa";
import { customerAxios } from '../../../config/axios';
import { toast } from 'react-hot-toast'
import Spinner from '../../common/spinners/SpinWithMessage'
import { PiCardholderDuotone } from "react-icons/pi";
import ServiceCard from '../service-card/ServiceCard';
import InstallationCard from '../service-card/InstallationCard'
import { useNavigate } from 'react-router-dom';

const CustomerCardDetails = ({ cid }) => {
    const [customer, setCustomer] = useState({})
    const [wholeHouse, setWholeHouse] = useState({})
    const [cardList, setCardList] = useState([])
    const [cardImageList, setCardImageList] = useState([])
    const [loading, setLoading] = useState('fetch')
    const navigate = useNavigate()

    useEffect(() => {
        if (cid) {
            const customerDetails = customerAxios.get(`/profile?cid=${cid}&detail=YES`).then((response) => {
                setCustomer(response.data)
            })

            const whDetails = customerAxios.get(`/service/whole-house?cid=${cid}&spare=YES`).then((response) => {
                setWholeHouse(response.data)
            })

            const serviceCardList = customerAxios.get(`/service/whole-house/cards?cid=${cid}&spare=YES`).then((response) => {
                setCardList(response.data)
            })

            const cardImageList = customerAxios.get(`/service/image?cid=${cid}`).then((response) => {
                setCardImageList(response.data)
            })


            Promise.all([customerDetails, whDetails, serviceCardList, cardImageList])
                .then(() => setLoading('')).catch(() => {
                    toast.error('Some error, Try now!')
                    navigate(-1)
                })
        }
    }, [cid])



    return (
        <div className="customer-card-details">
            <div className="top-div">
                <h3>#{customer?.cid}<span>_{(customer?.full_name)?.toUpperCase()}</span></h3>
            </div>
            <div className="section-border-div">
                <div className="left-div">
                    {/* Personal Details */}
                    <div className="list-border-div">
                        <div className="list-title">
                            <FaDotCircle />
                            <h4>Personal details</h4>
                        </div>
                        {loading
                            ? <Spinner message='Loading...' height={'150px'} />
                            : <div className="title-content">
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Address, Place</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.address?.address}, {customer?.address?.place}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Post, Land mark</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.address?.post} (P.O), {customer?.address?.land_mark}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>District, Pin</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.address?.district}, {customer?.address?.pin_code}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>State</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.address?.state}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Contact 1</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.contact1}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Contact 2</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.contact2}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Whatsapp</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.whatsapp1}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Zone</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.zone}</p>
                                    </div>
                                </div>
                                <div className="list-div credit-amt">
                                    <div className="one-div">
                                        <p>Credit amount</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: ₹{customer?.credit_amount || 0}</p>
                                    </div>
                                </div>
                                <div className="list-div debit-amt">
                                    <div className="one-div">
                                        <p>Debit amount</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: ₹{customer?.debit_amount || 0}</p>
                                    </div>
                                </div>
                            </div>}
                    </div>

                    {/* Service Details */}
                    <div className="list-border-div">
                        <div className="list-title">
                            <FaDotCircle />
                            <h4>Whole house details</h4>
                        </div>
                        {loading
                            ? <Spinner message='Loading...' height={'150px'} />
                            : <div className="title-content">
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Model</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {wholeHouse?.product_name}</p>
                                    </div>
                                </div>
                                {wholeHouse?.installed_at && <div className="list-div">
                                    <div className="one-div">
                                        <p>Installation date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${new Date(wholeHouse?.installed_at).toDateString()}`}</p>
                                    </div>
                                </div>}
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Whole house status</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: <span className={`text-badge ${wholeHouse?.wh_customer_status}-text`}>{wholeHouse?.wh_customer_status}</span></p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Package Start date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${wholeHouse?.package_started_date ? new Date(wholeHouse?.package_started_date).toDateString() : '-'}`}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Package Expiry date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${wholeHouse?.package_expiry_date ? new Date(wholeHouse?.package_expiry_date).toDateString() : '-'}`}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Next service date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${wholeHouse?.next_periodical_service_date ? new Date(wholeHouse?.next_periodical_service_date).toDateString() : '-'}`}</p>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
                <div className="right-div">
                    <div className="div-border">
                        <div className="list-title">
                            <FaDotCircle />
                            <h4>Service cards</h4>
                        </div>
                        <div className="color-info-div">
                            <div className="color-div">
                                <div className='color-box amount'></div>
                                <p>Amount</p>
                            </div>
                            <div className="color-div">
                                <div className='color-box primary'></div>
                                <p>Work description</p>
                            </div>
                        </div>
                        <div className="card-list-div">
                            {!cardList?.[0] && !cardImageList?.[0] &&
                                <Spinner height={'300px'} message='No service cards' icon={<PiCardholderDuotone />} spin={false} />
                            }
                            {cardList?.[0] &&
                                <>{cardList?.map((card, index) => {
                                    if (card?.installation) {
                                        return <InstallationCard key={index} data={card} />
                                    } else if (card?.re_installation) {
                                        return <InstallationCard key={index} data={card} re={true} />
                                    } else if (card?.service_card) {
                                        return <ServiceCard key={index} data={card} />
                                    }
                                })}</>}
                        </div>
                        <div className="card-image-div">
                            {cardImageList?.[0] && <>
                                {cardImageList?.map((card) => <img alt={card.image.key} src={card.image.url} />)}
                            </>}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CustomerCardDetails