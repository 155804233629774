import React from 'react'
import AdminPage from '../../../components/common/page/AdminPage'
import CustomerCardDetails from '../../../components/user/customer-details/CustomerCardDetails';
import { useParams } from 'react-router-dom';

const CustomerDetails = () => {
  const { cid } = useParams();


  return (
    <div className="customer-details-div">
      <AdminPage titleArray={['Customer details']}>
        <CustomerCardDetails cid={cid} />
      </AdminPage>
    </div>
  )
}

export default CustomerDetails